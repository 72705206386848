import { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Header2 from "../Header2/Header2";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import "./Training_resource.css";
import playButton from "./Frame_264.webp";
import training2 from "./training2.webp";
import up from "./up.webp";
import down from "./down.webp";
import playIcon from "./Frame_264.webp";
import API from "../helper/ApiHelper";
import pdf from "./PDF1.webp";
import pdfen from "../WhatIsACT/pdf_en.png";
import slugify from "slugify";
import test from "./Image.webp";
import BeatLoader from "react-spinners/BeatLoader";
import Loading from "../Loading/Loading";

export type TrainingMaterial = {
  id: string;
  material: {
    title: string;
    content11: string;
    icaption1: string;
    content12: string;
    image1: string;
    vcaption1: string;
    content2: string;
    video1: string;
    icaption2: string;
    content3: string;
    image2: string;
    vcaption2: string;
    content4: string;
    video2: string;
    pdf: string;
    link: string;
    id: string;
    position: number;
  };
};

function TrainingResource({
  props,
  baseRoute,
  cardIndex,
}: {
  props: TrainingMaterial[];
  baseRoute: string;
  cardIndex: number;
}) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Language Control
  const language = useSelector((state: RootState) => state.language.language);
  const [loadingText, setLoadingText] = useState("Loading");
  const [isListExpand, setIsListExpand] = useState(false);
  const role = useSelector((state: RootState) => state.role.role);
  const [lanString, setLanString] = useState([] as any);
  const navigate = useNavigate();
  //const language = "zh_hant";
  //console.log("language: ", language);
  let languageLabel: number = 1;
  if (language === "zh_hant") {
    languageLabel = 0;
    //console.log("languageLabel: ", languageLabel);
  } else {
    languageLabel = 1;
    //console.log("languageLabel: ", languageLabel);
  }

  const dropDownList = props.map((item, index) =>
    props[index].material.title != "" ? (
      <Link
        to={`${baseRoute}/${"training-materials/" +
          slugify(props[index].material.link, { lower: true }) +
          "-" +
          props[index].material.id
          }`}
      >
        <div
          key={index}
          id="dropDownRect"
          className={`dropDownRect${cardIndex === index ? " selected" : ""}`}
        // onClick={() => handleItemClick(index)}
        >
          {props[index].material.title}
        </div>
      </Link>
    ) : (
      <></>
    )
  );

  // const handleItemClick = (index: number) => {
  //   setCardIndex(index);
  // };

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (language === "zh_hant") {
      setLanString("/ch");
    } else if (language === "en") {
      setLanString("/en");
    }
    // console.log("lanstring",lanString);
  }, [language]);

  useEffect(() => {
    if (role !== "trainee") {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    setIsListExpand(false);
    // console.log(props.length);
    // console.log("dropDown", dropDownList.length);
  }, [props]);
  useEffect(() => {
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = props[cardIndex].material.title + " - ACTuwise";
    } else {
      setLoadingText("Loading");
      document.title = props[cardIndex].material.title + " - ACTuwise";
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  });
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }

  //[page] [section] 培訓資料 page (after "trainee" login)

  return (
    <div id="resource-holder">
      <div className="general-header">
        <Header2
          url={
            "/professional-training/training-materials/" +
            slugify(props[cardIndex].material.link, { lower: true }) +
            "-" +
            props[cardIndex].material.id
          }
        />
      </div>
      <div id="resource-container">
        <div id="resource-upper-part-container">
          {languageLabel === 0 ? (
            <h3 id="training-subpage-title">培訓資料</h3>
          ) : (
            <h3 id="training-subpage-title">ACT Training Material</h3>
          )}

          <img id="training-resource-top-img" src={training2} alt=""></img>

          <div
            id="training-resource-info-expand"
            onClick={() => setIsListExpand(!isListExpand)}
          >
            {languageLabel === 0 ? (
              <div id="resource-list-text">目錄</div>
            ) : (
              <div id="resource-list-text">Table of Contents</div>
            )}

            {isListExpand ? (
              <img className="resource-expansion-button" src={down} alt="" />
            ) : (
              <img className="resource-expansion-button" src={up} alt="" />
            )}
          </div>
          {isListExpand && <div id="list-drop-box">{dropDownList}</div>}
          {props[cardIndex] && props[cardIndex].material.pdf !== API.getPDF && (
            <div id="training-resource-pdf-icon">
              <div>{props[cardIndex] && props[cardIndex].material.pdf}</div>
              <a href={props[cardIndex].material.pdf}>
                <img
                  id="training-resource-pdf-image"
                  src={language == "en" ? pdfen : pdf}
                  alt=""
                />
              </a>
            </div>
          )}
        </div>

        <div id="resource-content-sections">
          <div id="resource-session-one">
            {props[cardIndex] && (
              <p id="session1-title">{props[cardIndex].material.title}</p>
            )}
            {props[cardIndex] && (
              <div
                className="resource-session-paragraph"
                dangerouslySetInnerHTML={{
                  __html: props[cardIndex].material.content11,
                }}
              ></div>
            )}
            {props[cardIndex] && (
              <img
                className="resource-session-img"
                src={props[cardIndex].material.image1}
                alt=""
              ></img>
              //<img src={`${ApiHelper.getImage}${props[cardIndex].material.image1}`} alt=""></img>
            )}
            {props[cardIndex] && (
              <p className="resource-session-caption">
                {props[cardIndex].material.icaption1}
              </p>
            )}
            {props[cardIndex] && (
              <div
                className="resource-session-paragraph"
                dangerouslySetInnerHTML={{
                  __html: props[cardIndex].material.content12,
                }}
              ></div>
            )}
          </div>
          {props[cardIndex] && props[cardIndex].material.video1 !== "" && (
            <div id="resource-session-two">
              {props[cardIndex] && (
                <iframe
                  className="video-player"
                  id="videoContainer"
                  width="100%"
                  key={dropDownList[cardIndex].key}
                  // src={
                  //   "https://www.youtube.com/embed/" +
                  //   props[cardIndex].material.video1
                  //     ?.split("https://www.youtube.com/watch?v=")
                  //     ?.at(-1)
                  //     ?.split("&")[0]
                  // }
                  src={props[cardIndex].material.video1 || ""}
                >
                  {/* <source
                  src={"https://www.youtube.com/embed/"+ props[cardIndex].material.video1?.split("https://www.youtube.com/watch?v=")?.at(-1)}
                    // src={`${ApiHelper.getVideo}${props[cardIndex].material.video1}`}
                    // type="video/mp4"
                  ></source>
                  {playIcon && <img className={"playBtn"} src={playButton} />} */}
                </iframe>
              )}
              {props[cardIndex] && (
                <p className="resource-session-caption">
                  {props[cardIndex].material.vcaption1}
                </p>
              )}
              {props[cardIndex] && (
                <div
                  className="resource-session-paragraph"
                  dangerouslySetInnerHTML={{
                    __html: props[cardIndex].material.content2,
                  }}
                ></div>
              )}
            </div>
          )}
          {props[cardIndex] && props[cardIndex].material.image2 !== "" &&
            (props[cardIndex]?.material?.icaption2) &&
            (
              <div id="resource-session-three">
                {props[cardIndex] && (
                  <img src={props[cardIndex].material.image2} alt=""></img>
                  //<img src={`${ApiHelper.getImage}${props[cardIndex].material.image2}`} alt=""></img>
                )}
                {props[cardIndex] && (
                  <p className="resource-session-caption">
                    {props[cardIndex].material.icaption2}
                  </p>
                )}
                {props[cardIndex] && (
                  <div
                    className="resource-session-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: props[cardIndex].material.content3,
                    }}
                  ></div>
                )}
              </div>
            )}
          {props[cardIndex] && props[cardIndex].material.video2 !== "" && (
            <div id="resource-session-four">
              {props[cardIndex] && (
                <iframe
                  className="video-player"
                  id="videoContainer"
                  width="100%"
                  key={dropDownList[cardIndex].key}
                  // src={"https://www.youtube.com/embed/"+ props[cardIndex].material.video2?.split("https://www.youtube.com/watch?v=")?.at(-1)?.split("&")[0]}
                  src={props[cardIndex].material.video2 || ""}
                >
                  {/* <source
                  src={"https://www.youtube.com/embed/"+ props[cardIndex].material.video2?.split("https://www.youtube.com/watch?v=")?.at(-1)}
                    // src={`${ApiHelper.getVideo}${props[cardIndex].material.video2}`}
                    // type="video/mp4"
                  ></source>
                  {playIcon && <img className={"playBtn"} src={playButton} />} */}
                </iframe>
              )}
              {props[cardIndex] && (
                <p className="resource-session-caption">
                  {props[cardIndex].material.vcaption2}
                </p>
              )}
              {props[cardIndex] && (
                <div
                  className="resource-session-paragraph"
                  dangerouslySetInnerHTML={{
                    __html: props[cardIndex].material.content4,
                  }}
                ></div>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TrainingResource;
